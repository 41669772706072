/* App -------------------------------- */

/*
Branding Colors:

Primary: #383B53;
Accent: #824C71;
Accent 2: #C4CAD0;
Button Hover: #4A2545;
White: #FCF7FF;

*/

.app {
  max-width: 1400px;
  margin: auto;
  padding: 20px;
}

.app-column {
  display: inline-block;
  vertical-align: top;
}

.ch-width-60 {
  width: 60%;
}

.ch-width-40 {
  width: 40%;
}

input[type=submit] {
  -webkit-appearance: none;
}

/* LocationDisplayWindow -------------------------------- */

.ch-location-window {
  padding: 0px 30px;
}

.ch-location-window .ch-location-content {
  border-style: solid;
  border-width: 3px;
  border-radius: 3px;
  border-color:   #383B53;
  padding: 10px;
  white-space: pre-wrap;
  overflow-y: scroll;
  max-height: 400px;
}

.ch-location-window .ch-audio-container p {
  font-weight: lighter;
  padding-left: 30px;
}

.ch-location-window .ch-audio-container .ch-audio-player {
  height: 35px;
}

.ch-location-window button.ch-edit-button {
  background-color:   #824C71;
  color: #FCF7FF;
  border-radius: 4px;
  font-size: 12px;
  padding: 6px 18px;
  cursor: pointer;
  transition: background-color 0.25s;
  border-style: none;
  margin-top: 15px;
}

.ch-location-window button.ch-edit-button:hover {
  background-color: #4A2545;
}

/* JournalSearchWindow -------------------------------- */

.ch-journal-window {
  padding: 0px 30px;
}

.ch-create-journal {
  vertical-align: middle;
  margin-left: 5px;
}

div.ch-journal-window .ch-autocomplete-container input {
  border-radius: 3px;
  border-color:   #383B53;
}

.ch-journal-window .ch-autocomplete-container input, .ch-journal-window .ch-autocomplete-container .ch-autocomplete-dropdown button {
  padding: 5px 6px;
  border-style: solid;
  border-width: 3px;
  border-color:   #383B53;
  font-size: 15px;
  width: 100%;
  box-sizing: border-box;
  max-width: 400px;
}

.ch-journal-window .ch-autocomplete-container .ch-autocomplete-dropdown button {
  text-align: left;
  background-color: #FCF7FF;
  color: #000;
  border-radius: 0px;
  transition: background-color 0.2s;
  font-size: 15px;
  border-top-style: none;
  cursor: pointer;
}

.ch-journal-window .ch-autocomplete-container .ch-autocomplete-dropdown button:hover, .ch-journal-window .ch-autocomplete-container .ch-autocomplete-dropdown button:focus {
  background-color:   #4A2545;
  color: #fff;
}

.ch-journal-window .ch-autocomplete-container .ch-autocomplete-dropdown button:focus {
  outline: none;
}

.ch-journal-window .ch-journal-content {
  border-style: solid;
  border-width: 3px;
  padding: 10px;
  margin: 10px 0px;
  border-radius: 3px;
  white-space: pre-wrap;
  overflow-y: scroll;
  max-height: 400px;
  border-color:   #383B53;
}

.ch-journal-window .ch-journal-content button, .ch-journal-window button {
  background-color:   #824C71;
  color: #FCF7FF;
  border-radius: 4px;
  font-size: 12px;
  padding: 6px 18px;
  cursor: pointer;
  transition: background-color 0.25s;
  border-style: none;
}

.ch-journal-window .ch-journal-content button:hover, .ch-journal-window button:hover {
  background-color: #4A2545;
  color: #FCF7FF;
}

.ch-journal-window .ch-journal-content .ch-journal-image {
  display: block;
  padding: 10px;
  max-width: 90%;
  max-height: 350px;
  min-width: 50px;
  min-height: 50px;
  font-style: italic;
  font-size: 12px;
}

/* NavigationTabs -------------------------------- */

.ch-navigation-tabs {
  overflow-x: scroll;
  white-space: nowrap;
  margin-bottom: -17px;
}

.ch-navigation-tabs button:hover {
  background-color:   #4A2545;
}

.ch-navigation-tabs button {
  border-style: none;
  border-radius: 5px 5px 0px 0px;
  background-color: #824C71;
  margin-right: 5px;
  cursor: pointer;
  vertical-align: bottom;
  font-size: 14px;
  color: #FCF7FF;
  padding: 8px 14px;
  transition: background-color 0.3s;
}

.ch-navigation-tabs button:focus {
  outline: none;
}

.active {
  background-color: #4A2545 !important;
  color: #FCF7FF;
}

/* Login -------------------------------- */

.video-container {
  text-align: center;
}

.ch-login-form {
  width: 400px;
  max-width: 90%;
  margin: auto;
  padding: 0px 30px;
  position: absolute;
  top: 40%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  animation-duration: 1.15s;
  animation-name: fadeup;
}

.ch-login-form input {
  padding: 5px 6px;
  border-style: solid;
  border-radius: 3px;
  border-width: 2px;
  border-color:   #383B53;
  font-size: 15px;
  width: 100%;
  display: block;
  margin-bottom: 5px;
  box-sizing: border-box;
}

.ch-login-form .ch-login-submit {
  background-color:   #824C71;
  color: #FCF7FF;
  border-radius: 4px;
  font-size: 12px;
  padding: 6px 18px;
  cursor: pointer;
  transition: background-color 0.25s;
  border-style: none;
}

.ch-login-form .ch-login-submit:hover {
  background-color: #4A2545;
  color: #FCF7FF;
}

.ch-login-form form ul {
  font-size: 12px;
}

.ch-login-form form a {
  text-decoration: none;
  margin-bottom: 10px;
  display: block;
  color: #383B53;
  font-size: 14px;
  opacity: 0.7;
  transition: opacity 0.15s;
}

.ch-login-form form a:hover {
  opacity: 1;
}

/* MapWindow -------------------------------- */

.ch-map-window-container {
  display: inline-block;
  width: 100%;
  position: relative;
  height: 85vh;
}

.ch-map-window-container .ch-welcome {
  background-color: #fff;
  text-align: center;
  width: 470px;
  max-width: 100%;
  margin: 10% auto;
  border-radius: 3px;
  display: block;
  padding: 6%;
  font-size: 1.5em;
}

.ch-map-window-container .ch-welcome p {
  margin: 3px;
}

.ch-map-window-container .ch-welcome .ch-title-branding {
  font-size: 2.4em;
  border-bottom-style: solid;
  display: inline-block;
  margin: 5px 0px;
  border-width: 5px;
  padding-bottom: 5px;
  border-bottom-color: #fff;
}

.ch-map-window-container .ch-map-window {
  background-color:   #C4CAD0;
  overflow: scroll;
  border-style: solid;
  border-radius: 0px;
  border-width: 3px;
  border-color: #383B53;
  height: 99%;
}

.ch-map-window-container .ch-map-window .ch-map-zoom-adjust {
  width: 100%;
  height: auto;
  position: relative;
  display:inline-block;
}

.ch-map-window-container .ch-map-window .ch-map-zoom-adjust img {
  width: 100%;
}

.ch-zoom-container {
  position: absolute;
  left: 30px;
  bottom: 40px;
}

.ch-zoom-container button {
  display: block;
  cursor: pointer;
  padding: 0px;
  background-color: #824C71;
  width: 30px;
  height: 30px;
  transition: background-color 0.25s;
  color: #FCF7FF;
  border-radius: 4px;
  border-style: none;
  margin: 4px 0px;
  -webkit-box-shadow: 3px 3px 13px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: 3px 3px 13px 0px rgba(0,0,0,0.75);
  box-shadow: 3px 3px 13px 0px rgba(0,0,0,0.75);
}

.ch-zoom-container button img {
  width: 56%;
}

.ch-zoom-container button:hover {
  background-color: #4A2545;
}

.ch-zoom-container button:focus {
  outline: none;
}

/* Media Uploader Selection -------------------------------- */

.ch-file-upload input {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}

.ch-media-uploader button, .ch-file-upload label, .ch-media-display-item .ch-purple-button {
  background-color:   #824C71;
  color: #FCF7FF;
  border-radius: 4px;
  font-size: 12px;
  margin: 30px 0px;
  padding: 6px 18px;
  cursor: pointer;
  transition: background-color 0.25s;
  border-style: none;
}

.ch-media-uploader button:hover {
  background-color: #4A2545;
}

.ch-media-display-item {
  width: 25%;
  height: 140px;
  display: inline-block;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  background-position: center;
}

.ch-media-display {
  margin-top: 20px;
}

.ch-media-display-item .ch-media-select-button {
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  position: absolute;
  margin: 0px;
}

.ch-media-display-item .overlay {
  height: 100%;
  opacity: 0;
  transition: opacity 0.125s;
  background-color: rgba(0, 0, 0, 0.75);
}

.ch-media-display-item:hover .overlay {
  opacity: 1;
}

.ch-media-display-item.ch-music-item {
  text-align: center;
  height: auto;
  border-style: solid;
  font-weight: bold;
  border-color: #C4CAD0;
  border-width: 1px;
  border-radius: 3px;
  padding: 5px 0px;
  margin: 10px 3px;
}

.chModal .ch-media-display {
  max-height: 200px;
  overflow-y: scroll;
  background-color: lightgrey;
  text-align: center;
}

.chModal .ch-media-display-item.selected {
  border-style: solid;
  border-width: 2px;
  border-color: gold;
}

.chModal .ch-media-display-item.ch-music-item {
  width: 47%;
  background-color: #fff;
}

.chModal .ch-media-display-item.ch-music-item .ch-purple-button {
  margin: 15px 0px;
}

.ch-media-display-item.ch-music-item audio {
  width: 97%;
}

/* Campaign Selection -------------------------------- */

.app.ch-campaign-selection {
  max-width: 90%;
  width: 440px;
  margin-top: 8%;
}

.app.ch-campaign-selection .ch-gray-panel {
  background-color: #383B53;
  padding: 20px;
  border-radius: 3px;
  animation-duration: 1.15s;
  animation-name: fadeup;
}

.app.ch-campaign-selection .ch-gray-panel h1 {
  margin-top: 0px;
  margin-bottom: 10px;
  color: #fff;
}

.app.ch-campaign-selection .ch-gray-panel button {
  padding: 6px 16px;
  margin: 5px;
  display: inline-block;
}

.app.ch-campaign-selection button {
  background-color: #824C71;
  color: #FCF7FF;
  border-radius: 4px;
  font-size: 15px;
  padding: 6px 18px;
  display: block;
  cursor: pointer;
  transition: background-color 0.25s;
  border-style: none;
}

.app.ch-campaign-selection input {
  outline: none;
  border-style: none none solid none;
  margin: 10px 0px;
  width: 100%;
  max-width: 400px;
  font-size: 18px;
}

.app.ch-campaign-selection button:hover {
  background-color: #4A2545;
}

/* Pins -------------------------------- */

.ch-pin {
  position: absolute;
  width: 24px;
  transition: transform 0.15s;
  cursor: pointer;
}

.ch-pin:hover {
  transform: scale(1.1);
}

.ch-pin:hover .ch-pin-title {
  opacity: 100 !important;
}

.ch-pin-title {
  position: absolute;
  width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
  top: -2.2em;
  padding: 3px 4px;
  white-space: nowrap;
  margin-left: -55px;
  color: #FCF7FF;
  border-radius: 3px;
  font-size: 0.9em;
  text-align: center;
  background-color: rgba(0,0,0,0.75);
}

/* Modals -------------------------------- */

.chModal {
    max-width: 600px;
    width: 80%;
    border-radius: 3px;
}

.chModal h2 {
  text-align: center;
}

.chModal p {
  font-weight: bold;
}

.chModal input:not([type='checkbox']), .chModal textarea {
  width: 80%;
}

.chModal .ch-important-input {
  margin-top: 20px;
}

.chModal textarea {
  min-height: 100px;
  max-width: 90%;
  min-width: 30%;
}

.chModal .ch-submit-button {
  margin-top: 20px;
}

.chModal button {
  cursor: pointer;
}

.chModal .ch-submit-button button {
  background-color:  #824C71;
  color: #FCF7FF;
  border-radius: 4px;
  font-size: 12px;
  padding: 6px 18px;
  cursor: pointer;
  border-style: none;
  transition: background-color 0.25s;
}

.chModal .ch-submit-button button:hover {
  background-color: #4A2545;
  color: #FCF7FF;
}


/* Tutorial Modal -------------------------------- */

.ch-tutorial {
  max-height: 450px;
  overflow-y: scroll;
}

.ch-tutorial h2:focus {
  outline: none;
}

.ch-tutorial h3 {
  color: #4A2545;
}

.ch-tutorial h4 {
  color: grey;
}

.ch-tutorial p {
  font-weight: normal;
}

.ch-tutorial .ch-social {
  background-color: lightgrey;
  font-size: 17px;
  padding: 10px;
  font-weight: bold;
  width: 520px;
}

/* Toggle Panel ------------------------------*/

.ch-togglepanel-nav > button {
  border-style: none;
  border-radius: 5px 5px 0px 0px;
  background-color: #824C71;
  margin-right: 5px;
  cursor: pointer;
  vertical-align: bottom;
  font-size: 14px;
  color: #FCF7FF;
  padding: 8px 14px;
  transition: background-color 0.3s;
}

.ch-togglepanel-nav > button:hover {
  background-color:   #4A2545;
}

.ch-togglepanel-nav > button:focus {
  outline: none;
}

.ch-togglepanel-body {
  border-style: solid;
  border-radius: 0px;
  border-width: 3px;
  border-color: #383B53;
  padding: 10px;
}

.ch-togglepanel {
  padding: 0px 20px;
}

/* InitiativeTracker -------------------------------- */

.ch-init-tracker input {
  padding: 5px 6px;
  border-style: solid;
  border-radius: 3px;
  border-width: 2px;
  border-color:   #383B53;
  font-size: 15px;
  margin-bottom: 0px;
  box-sizing: border-box;
  width: 50px;
}

.ch-init-modifier {
  margin-left: 5px;
}

.ch-init-tracker button {
  border-style: none;
  border-radius: 5px;
  margin-left: 5px;
  cursor: pointer;
  background-color: darkgrey;
  vertical-align: bottom;
  font-size: 14px;
  color: #FCF7FF;
  padding: 8px 14px;
}

.ch-init-tracker button:hover {
  background-color: #383B53;
}

.ch-init-tracker .ch-init-participant button:hover {
  background-color: #4A2545;
}

.ch-init-tracker .ch-init-participant .ch-init-name {
  min-width: 65px;
}

.ch-init-tracker .ch-init-participant button {
  border-style: none;
  border-radius: 5px;
  background-color: #824C71;
  margin-left: 5px;
  cursor: pointer;
  vertical-align: bottom;
  font-size: 14px;
  color: #FCF7FF;
  padding: 8px 14px;
  transition: background-color 0.3s;
}

.ch-init-tracker p {
  margin: 0px;
  display: inline-block;
  padding-right: 10px;
}

.ch-init-tracker .ch-init-participant {
  padding-top: 5px;
}

.ch-init-tracker .ch-init-add {
  padding-top: 20px;
}

.ch-init-tracker .ch-init-add p {
  display: block;
}

.ch-init-tracker .ch-init-add input {
  width: auto;
}

/* Generator -------------------------------- */

.ch-generators textarea {
  width: 98.5%;
  max-width: 98.5%;
  min-height: 100px;
  border-style: solid;
  border-color: lightgrey;
  border-radius: 2px;
}

.ch-generators textarea:focus {
  outline: none;
}

.ch-generator {
  border-style: solid;
  margin: auto;
  padding: 20px;
  background-color: lightgray;
  border-color: #824C71;
  position: relative;
  padding-top: 40px;
}

.ch-generator .ch-generator-results {
  font-weight: bold;
}

.ch-generators label {
  display: block;
}

.ch-generators span {
  font-weight: bold;
  cursor: pointer;
}

.ch-generator .ch-generator-delete {
  width: 30px;
  font-size: 10px;
  background-color: red;
  color: #fff;
  border-radius: 0px;
  position: absolute;
  left: 0px;
  top: 0px;
}

.ch-generators button {
  border-style: none;
  border-radius: 5px;
  background-color: #824C71;
  cursor: pointer;
  vertical-align: bottom;
  font-size: 14px;
  color: #FCF7FF;
  width: 100%;
  padding: 10px 0px;
}

/* Nav -------------------------------- */

.ch-mobile-navbar {
  display: none;
}

.ch-navbar, .ch-mobile-navbar {
  width: 100%;
  background-color:#383B53;
  text-align: right;
}

.ch-navbar-container {
  display: inline-block;
  padding-right: 30px;
}

.ch-navbar .ch-navbar-left {
  float: left;
  padding-left: 30px;
}

.ch-navbar .ch-navbar-left div.ch-hello {
  cursor: default;
}

.ch-navbar a, .ch-navbar button, .ch-navbar .ch-hello, .ch-mobile-navbar button, .ch-mobile-navbar .ch-hello, .ch-mobile-navbar a {
  padding: 10px 15px;
  text-decoration: none;
  color: #FCF7FF;
  cursor: pointer;
  display: inline-block;
  border-style: none;
  font-size: 14px;
  background-color: #383B53;
  transition: background-color 0.25s;
  text-transform: uppercase;
}

.ch-mobile-navbar .ch-hamburger-icon:focus {
  outline: none;
}

.ch-mobile-navbar .ch-mobile-dropdown {
  border-style: solid;
  border-width: 3px;
  border-color: #FCF7FF;
  background-color: #FCF7FF;
  position: absolute;
  width: 200px;
  right: 10px;
  z-index: 1000;
}

.ch-mobile-navbar button, .ch-mobile-navbar .ch-hello, .ch-mobile-navbar a {
  display: block;
  text-align: left;
  width: 100%;
  box-sizing: border-box;
}

.ch-navbar a:hover, .ch-navbar button:hover {
  background-color: #4A2545;
}

/* Global Scrollbar Fix -------------------------------- */

::-webkit-scrollbar {
    width: 1em;
    height: 1em;
    background-color: lightgrey;
}
 
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}
 
::-webkit-scrollbar-thumb {
  background-color: #824C71;
  border-radius: 3px;
  border-style: none;
}

/* Media Query -------------------------------- */


@media only screen and (max-width: 1100px) {
  .ch-media-display-item {
    width: 33%;
  }
}

/* Mobile Screens */
@media only screen and (max-width: 900px) {
  div.app-column {
    display: block;
    width: 100%;
  }
  div.ch-map-window-container {
    height: 55vh;
  }
  .ch-location-window {
    padding: 0px 15px;
  }
  .ch-location-window .ch-location-content {
    max-height: none;
    overflow: visible;
  }
  div.ch-journal-window {
    display: none;
  }
  .ch-media-display-item {
    width: 50%;
  }

  .ch-navbar {
    display: none;
  }
  .ch-mobile-navbar {
    display: block;
  }
}

@media only screen and (max-width: 650px) {
  .ch-media-display-item {
    width: 100%;
  }
}

/* Keyframe Animation -------------------------------- */

@keyframes fadeup {
  from {
    margin-top: 20px;
    opacity: 0;
  }

  to {
    margin-top: 0px;
    opacity: 1;
  }
}